/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  UserType,
  UserInfo,
  UserPropertyKeyType,
  AfterActionFeedbackType,
} from "redux/types";
import { getRtkQueryTagTypes } from "redux/utils";

export const userProfileApi = createApi({
  reducerPath: "userprofileapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<UserType, { brandId: string }>({
      query: ({ brandId }) => `${brandId}/user/`,
      providesTags: () => [{ type: "PersonalDetails" }],
    }),
    getUserInfo: builder.query<UserInfo, { brandId: string }>({
      query: ({ brandId }) => `${brandId}/userinfo/`,
    }),
    updateUser: builder.mutation<
      AfterActionFeedbackType,
      { brandId: string; body: Record<keyof UserPropertyKeyType, string> }
    >({
      query: ({ brandId, body }) => {
        return {
          url: `${brandId}/user/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: () => [{ type: "PersonalDetails" }],
    }),
    postLogin: builder.mutation<
      (string | number | boolean)[],
      { brandId: string }
    >({
      query: ({ brandId }) => ({
        url: `/${brandId}/user/login/`,
        method: "POST",
        body: {},
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserInfoQuery,
  useUpdateUserMutation,
  usePostLoginMutation,
} = userProfileApi;
