/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import { TextInput, Button, Loading, DialogModal } from "components";
import { FormPageWrapper, FormWrapper, RowOfElements } from "pages/styles";
import { FIELD_REQUIRED_MESSAGE } from "utils";
import { usePostOauthTokenMutation } from "redux/features/loginapi/loginApi";
import {
  PortalBrandStateType,
  fetchBrandInfo,
} from "redux/features/portalbrand/portalBrandSlice";
import {
  usePostLoginMutation,
  useGetUserInfoQuery,
} from "redux/features/userprofileapi/userProfileApi";

type LoginFormDataType = {
  userEmail: string;
  userPassword: string;
};

const LoginAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Login() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  const { steps } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand.info
  );

  const [formData, setFormData] = useState<LoginFormDataType>({
    userEmail: "",
    userPassword: "",
  });
  const [userEmailErrorMessage, setUserEmailErrorMessage] = useState("");
  const [userPasswordErrorMessage, setUserPasswordErrorMessage] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [errorDialogData, setErrorDialogData] = useState<{
    show: boolean;
    dialogTitleText: string;
    dialogContentText: string;
  }>({ show: false, dialogTitleText: "", dialogContentText: "" });

  const onUsernameChange = (value: string) => {
    setFormData({ ...formData, userEmail: value });
  };
  const onPasswordChange = (value: string) => {
    setFormData({ ...formData, userPassword: value });
  };

  useEffect(() => {
    setLoginErrorMessage("");
  }, [formData]);

  const [postOauthToken, { isLoading }] = usePostOauthTokenMutation();
  const [postLogin] = usePostLoginMutation();
  const handleUserLogin = async () => {
    setIsFormDirty(true);

    if (!userEmailErrorMessage && !userPasswordErrorMessage) {
      try {
        const result = await postOauthToken({
          body: {
            realm: "Username-Password-Authentication",
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
            scope: "openid",
            grant_type: "http://auth0.com/oauth/grant-type/password-realm",
            username: formData.userEmail,
            password: formData.userPassword,
          },
        }).unwrap();

        if (result.access_token) {
          localStorage.setItem("access_token", result.access_token);
          localStorage.setItem("id_token", result.id_token);

          // document.cookie = `access_token=${result.access_token};max-age=604800;domain=td365.com`;

          const { isSuccess } = useGetUserInfoQuery({
            brandId: process.env.REACT_APP_WHITELABEL as string,
          });

          if (isSuccess) {
            postLogin({
              brandId: process.env.REACT_APP_WHITELABEL as string,
            });

            dispatch(fetchBrandInfo());

            navigate("/");
          } else {
            localStorage.setItem("incomplete_signup", "true");
            navigate("/signup");
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === 403) {
          setLoginErrorMessage(error.data.error_description);
        } else {
          setErrorDialogData({
            show: true,
            dialogTitleText: "Login Error",
            dialogContentText:
              error.data?.error_description ||
              "Something went wrong, please try again later!",
          });
        }
      }
    }
  };

  useEffect(() => {
    setUserEmailErrorMessage(formData.userEmail ? "" : FIELD_REQUIRED_MESSAGE);
  }, [formData.userEmail]);

  useEffect(() => {
    setUserPasswordErrorMessage(
      formData.userPassword ? "" : FIELD_REQUIRED_MESSAGE
    );
  }, [formData.userPassword]);

  const handleCloseErrorDialog = () => {
    setErrorDialogData({
      show: false,
      dialogTitleText: "",
      dialogContentText: "",
    });
  };

  const goToSignup = () => {
    localStorage.removeItem("incomplete_signup");
    navigate("/signup");
  };

  return (
    <>
      <Title>Login</Title>
      <FormPageWrapper>
        <Typography sx={{ marginBottom: "15px" }} variant="h5" component="h1">
          Log in to your account
        </Typography>
        <LoginAreaWrapper>
          <FormWrapper theme={theme}>
            <TextInput
              id="login_userid"
              title="Email address"
              value={formData.userEmail}
              size="small"
              required
              error={isFormDirty && !!userEmailErrorMessage}
              helperText={userEmailErrorMessage}
              onChange={onUsernameChange}
            />
            <TextInput
              id="login_password"
              title="Password"
              value={formData.userPassword}
              type="password"
              error={isFormDirty && !!userPasswordErrorMessage}
              autoComplete="current-password"
              size="small"
              required
              helperText={userPasswordErrorMessage}
              onChange={onPasswordChange}
            />
            {loginErrorMessage && (
              <Alert severity="error">{loginErrorMessage}</Alert>
            )}
            <Button type="button" onClick={handleUserLogin} text="Log in" />
            <RowOfElements justifyContent="space-between">
              <Button
                text="Forgot password?"
                variant="text"
                sx={{ color: theme.palette.primary.main }}
                onClick={() => navigate("/forgot-password")}
              />
              {steps.length > 0 && (
                <Button
                  text="Don't have an account?"
                  variant="text"
                  sx={{ color: theme.palette.primary.main }}
                  onClick={goToSignup}
                />
              )}
            </RowOfElements>
          </FormWrapper>
        </LoginAreaWrapper>
        {isLoading && <Loading />}
        {errorDialogData.show && (
          <DialogModal
            open
            title={errorDialogData.dialogTitleText}
            text={errorDialogData.dialogContentText}
            handleClose={handleCloseErrorDialog}
          />
        )}
      </FormPageWrapper>
    </>
  );
}
