/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, Fragment } from "react";
import { Title } from "react-head";
import {
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Checkbox,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  TextInput,
  Button,
  DatePicker,
  Loading,
  DialogModal,
  CustomSelect,
  RadioButton,
  ComboBox,
} from "components";
import {
  SignupGeneralBlockQuestionsType,
  SignupStepType,
  SignupGeneralInputType,
  SignupQuestionTypes,
  SignupPostDataType,
  SignupAuth0DataType,
  CqqObjectType,
  Auth0UserMetadata,
} from "redux/types";
import { mediaQueries, breakpoints } from "utils";
import {
  transformTextWithLinks,
  formatDate,
  passwordValidation,
  confirmPasswordValidation,
  emailValidation,
  dateOfBirthValidation,
  emptyValidation,
  telephoneValidation,
  getCookie,
  parseJwt,
  setCXDParameter,
} from "pages/utils";
import {
  useGetSignupQuestionsQuery,
  useGetAdditionalQuestionsQuery,
  usePostSignupDataMutation,
  useAuth0UpdateUserMetadataMutation,
  auth0Signup,
} from "redux/features/signupapi/signupApi";
import { PageContentWrapper } from "pages/styles";
import { usePostOauthTokenMutation } from "redux/features/loginapi/loginApi";
import { PortalBrandStateType } from "redux/features/portalbrand/portalBrandSlice";
import { usePostLoginMutation } from "redux/features/userprofileapi/userProfileApi";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: ${breakpoints[3]}px;

  ${mediaQueries[3]} {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const SignupFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 500px;
  padding: 30px 0;

  ${mediaQueries[1]} {
    padding: 60px 0;
  }
`;

const QuestionBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
`;

const SignupFormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`;
const SignupFormInputWrapper = styled.div`
  margin: 10px 0;
  max-width: 500px;
  width: 100%;
`;
const SubLabel = styled.span`
  color: #999;
`;

type SignupDataType = {
  key: string;
  value: string;
  type: SignupQuestionTypes;
  emptyValue: boolean;
  isCqq?: boolean;
  isDirty?: boolean;
};

type SignupFormDataType = Record<string, SignupDataType[]>;

const ADDRESS_COUNTRY_KEY = "addr_country";

function Signup() {
  const navigate = useNavigate();

  const { steps } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand.info
  );

  const [formData, setFormData] = useState<SignupFormDataType>(
    steps.reduce((acc, step, index) => {
      return { ...acc, [`${index}`]: [] };
    }, {})
  );

  useEffect(() => {
    const incompleteSignup = localStorage.getItem("incomplete_signup");
    const accessToken = localStorage.getItem("access_token");
    if (accessToken && !incompleteSignup) {
      navigate("/");
    }

    // CXD
    setCXDParameter();
  }, []);

  useEffect(() => {
    if (steps.length === 0) {
      navigate("/login");
    }
  }, [steps]);

  const [additionalFormData, setAdditionalFormData] =
    useState<SignupFormDataType>(
      steps.reduce((acc, step, index) => {
        return { ...acc, [`${index}`]: [] };
      }, {})
    );
  const [showFailCQQModal, setShowCQQFailModal] = useState(false);
  const [cqqAlertText, setCQQAlertText] = useState("");
  const [stepIndex, setStepIndex] = useState(0);
  const [skipAdditionalQuestion, setSkipAdditionalQuestion] = useState(true);
  const [userEmailExist, setUserEmailExist] = useState(false);
  const [lockEmailAndPassword, setLockEmailAndPassword] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [numberOfSteps, setNumberOfSteps] = useState(steps.length);
  const [errorDialogData, setErrorDialogData] = useState<{
    show: boolean;
    dialogTitleText: string;
    dialogContentText: string;
  }>({ show: false, dialogTitleText: "", dialogContentText: "" });

  const [updateUserMetadata, { isLoading: updateUserMetadataLoading }] =
    useAuth0UpdateUserMetadataMutation();
  const [postOauthToken, { isLoading: loginLoading }] =
    usePostOauthTokenMutation();
  const [postSignupData, { isLoading: postSignupLoading }] =
    usePostSignupDataMutation();

  const {
    data: pageData,
    isFetching: stepFetching,
    error: stepError,
  } = useGetSignupQuestionsQuery({ step: steps[stepIndex] });

  useEffect(() => {
    document.querySelector(".page-container")?.scrollTo(0, 0);
  }, [stepIndex]);

  useEffect(() => {
    if (stepError) {
      setErrorDialogData({
        show: true,
        dialogTitleText: "Page Content Failed",
        dialogContentText:
          "There was a problem retrieving the next page data. Please try again later.",
      });
      if (stepIndex > 0) {
        setStepIndex(stepIndex - 1);
      }
    }
  }, [stepError]);

  const getFormDataByKey = (key: string, additionalQuestion = false) => {
    let selectedData: SignupDataType | undefined;
    if (additionalQuestion) {
      selectedData = Object.keys(additionalFormData)
        .reduce(
          (acc, data) => [...acc, ...additionalFormData[data]],
          [] as SignupDataType[]
        )
        .find((data) => data.key === key);
    } else {
      selectedData = Object.keys(formData)
        .reduce(
          (acc, data) => [...acc, ...formData[data]],
          [] as SignupDataType[]
        )
        .find((data) => data.key === key);
    }

    if (!selectedData) {
      return {
        value: "",
        isDirty: false,
        isCqq: false,
        emptyValue: true,
        key: "",
        type: "" as SignupQuestionTypes,
      };
    }
    return selectedData;
  };

  const {
    data: additionalQuestions,
    isFetching: additionalQFetching,
    error: additionalQError,
  } = useGetAdditionalQuestionsQuery(
    {
      brandId: process.env.REACT_APP_WHITELABEL as string,
      country: getFormDataByKey(ADDRESS_COUNTRY_KEY).value,
    },
    { skip: skipAdditionalQuestion }
  );

  useEffect(() => {
    if (!formData[`${stepIndex}`].length && pageData) {
      let initialFormData: SignupDataType[] = [];
      Object.keys(pageData).forEach((pageDataKey) => {
        const blockQuestion: SignupGeneralBlockQuestionsType | undefined =
          pageData[pageDataKey as keyof SignupStepType];
        if (blockQuestion) {
          let userMetadata: { [field: string]: string } = {};
          if (stepIndex === 0) {
            const incompleteSignup = localStorage.getItem("incomplete_signup");
            if (incompleteSignup) {
              const idToken = localStorage.getItem("id_token");
              if (idToken) {
                const accessToken = localStorage.getItem("access_token");
                if (accessToken) {
                  setTokenId(accessToken);
                }
                const userData = parseJwt(idToken);
                const metadata = userData["https://tradenation/user_metadata"];
                if (metadata) {
                  userMetadata = {
                    ...metadata.about,
                    ...metadata.personal,
                    email: userData.email,
                    password: "Alpha01&",
                    confirm_password: "Alpha01&",
                  };
                  setStepIndex(1);
                  setLockEmailAndPassword(true);
                }
              }
            }
          }
          const blockFormData = blockQuestion.properties.map(
            ({ key, type, emptyValue, isCqq }) => ({
              key,
              value: userMetadata[key] || "",
              type,
              emptyValue,
              isDirty: false,
              isCqq,
            })
          );
          initialFormData = [...initialFormData, ...blockFormData];
        }
      });

      setFormData({ ...formData, [`${stepIndex}`]: initialFormData });
    }
  }, [pageData]);

  useEffect(() => {
    setNumberOfSteps(
      getFormDataByKey(ADDRESS_COUNTRY_KEY).value !== "AU" &&
        process.env.REACT_APP_WHITELABEL === "TD365AU"
        ? steps.length - 1
        : steps.length
    );
    if (additionalQuestions) {
      const initialAdditionalData = additionalQuestions.properties.map(
        ({ key, type, emptyValue, isCqq }) => ({
          key,
          value: "",
          type,
          emptyValue,
          isDirty: false,
          isCqq,
        })
      );
      setAdditionalFormData({
        ...additionalFormData,
        [`${stepIndex}`]: initialAdditionalData,
      });
    }
  }, [additionalQuestions]);

  const validateFormFieldError = ({
    key,
    value,
    emptyValue,
    type,
  }: SignupDataType) => {
    switch (key) {
      case "password":
        return passwordValidation(value);
      case "confirm_password":
        return confirmPasswordValidation(
          value,
          getFormDataByKey("password").value
        );
      case "email":
        return emailValidation(value, userEmailExist);
      case "date_of_birth":
        return dateOfBirthValidation(value);
      case "telephone":
        return telephoneValidation(value);
      default:
        if (!emptyValue) {
          if (type === "checkbox") {
            return emptyValidation(value, true);
          }
          return emptyValidation(value, false);
        }
        return { isValid: true, errorHelper: null };
    }
  };

  const validateData = (validationData: SignupFormDataType) => {
    const invalidData = validationData[`${stepIndex}`].find((data) => {
      const fieldValidation = validateFormFieldError(data);
      if (!fieldValidation.isValid) {
        return true;
      }
      return false;
    });
    return !invalidData;
  };

  const isFormValid = () => {
    if (validateData(formData) && validateData(additionalFormData)) {
      return true;
    }
    return false;
  };

  const updateDataForm = (form: SignupFormDataType, data: SignupDataType) => {
    const selectedData = form[`${stepIndex}`].find((d) => d.key === data.key);
    const unSelectedData = form[`${stepIndex}`].filter(
      (fdata) => fdata.key !== data.key
    );
    if (!selectedData) {
      const newData = {
        ...form,
        [`${stepIndex}`]: [...unSelectedData, { ...data, isDirty: true }],
      };

      return newData;
    }
    const newData = {
      ...form,
      [`${stepIndex}`]: [
        ...unSelectedData,
        { ...selectedData, value: data.value, isDirty: true },
      ],
    };
    return newData;
  };

  const handleFormDataOnChange = (
    data:
      | SignupDataType & {
          additionalQuestion: boolean;
        }
  ) => {
    if (data.key === ADDRESS_COUNTRY_KEY) {
      setSkipAdditionalQuestion(false);
    }
    if (data.key === "email" && userEmailExist) {
      setUserEmailExist(false);
    }
    if (data.key === "telephone") {
      const newValue = data.value.replace(/\D/g, "");
      data = { ...data, value: `+${newValue}` };
    }

    if (data.additionalQuestion) {
      const newData = updateDataForm(additionalFormData, data);
      setAdditionalFormData(newData);
    } else {
      const newData = updateDataForm(formData, data);
      setFormData(newData);
    }
  };

  const renderTextWithLink = (label: string) => {
    return (
      <div>
        {transformTextWithLinks(label).map((val) => (
          <Fragment key={val.key}>{val.display}</Fragment>
        ))}
      </div>
    );
  };

  const renderQuestions = (questions: SignupGeneralInputType[]) => {
    return questions.map((question) => {
      const {
        label,
        key,
        type,
        labelHelper,
        emptyValue,
        options,
        additionalQuestion,
      } = question;
      let elementValidation: {
        isValid: boolean;
        errorHelper: null | JSX.Element;
      } = { isValid: true, errorHelper: null };
      const elementData = getFormDataByKey(key, additionalQuestion);
      if (elementData.isDirty) {
        elementValidation = validateFormFieldError(elementData);
      }
      switch (type) {
        case "text":
        case "password":
          return (
            <SignupFormInputWrapper key={key}>
              <SignupFormLabel htmlFor={`signup_${key}`}>
                {label}
              </SignupFormLabel>
              <TextInput
                id={`signup_${key}`}
                value={elementData.value}
                type={type}
                size="small"
                error={!elementValidation.isValid}
                helperText={elementValidation.errorHelper}
                onChange={(value: string) => {
                  handleFormDataOnChange({
                    key,
                    value,
                    type,
                    emptyValue,
                    additionalQuestion: !!additionalQuestion,
                  });
                }}
                disabled={
                  lockEmailAndPassword &&
                  ["email", "password", "confirm_password"].includes(key)
                }
              />
            </SignupFormInputWrapper>
          );

        case "select":
          if (key.includes("_currency")) {
            const selectedPlatform = formData[
              // picking trading_platform on TD365UK is on step 1 instead of 2
              (process.env.REACT_APP_WHITELABEL as string) === "TD365UK" ? 1 : 2
            ].find((data) => data.key === "trading_platform")?.value;

            if (key !== `${selectedPlatform}_currency`) {
              return null;
            }
          }

          return (
            <SignupFormInputWrapper key={key}>
              <SignupFormLabel htmlFor={`signup_${key}`}>
                {label}
              </SignupFormLabel>
              <CustomSelect
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  "&&& > div": { padding: "10px 14px" },
                }}
                value={elementData.value}
                options={options || []}
                onChangeHandler={(value: string | number) => {
                  const newData = {
                    key,
                    value: `${value}`,
                    type,
                    emptyValue,
                    additionalQuestion: !!additionalQuestion,
                  };

                  if (key === "trading_platform") {
                    let newFormData = updateDataForm(formData, newData);

                    questions.forEach((q) => {
                      if (q.key.includes("_currency")) {
                        let currencyValue = q.options ? q.options[0].value : "";
                        if (q.key === `${value}_currency`) {
                          currencyValue = "";
                        }
                        const newCurrencyData = {
                          key: q.key,
                          value: `${currencyValue}`,
                          type: q.type,
                          emptyValue: q.emptyValue,
                          additionalQuestion: !!q.additionalQuestion,
                        };
                        newFormData = updateDataForm(
                          newFormData,
                          newCurrencyData
                        );
                      }
                    });

                    setFormData(newFormData);
                  } else {
                    handleFormDataOnChange(newData);
                  }
                }}
              />
            </SignupFormInputWrapper>
          );

        case "autoselect":
          return (
            <Fragment key={key}>
              <SignupFormInputWrapper>
                <SignupFormLabel htmlFor={`signup_${key}`}>
                  {label}
                </SignupFormLabel>
                <ComboBox
                  sx={{
                    width: "100%",
                    maxWidth: "500px",
                    "&&& > div > div": { padding: "2px" },
                    "&&& input": { paddingLeft: "14px" },
                  }}
                  id={key}
                  options={options || []}
                  value={elementData.value}
                  onChangeHandler={(option: {
                    label: string;
                    value: string;
                  }) => {
                    handleFormDataOnChange({
                      key,
                      value: option.value,
                      type,
                      emptyValue,
                      additionalQuestion: !!additionalQuestion,
                    });
                  }}
                />
              </SignupFormInputWrapper>
              {key === "addr_country" && additionalQError && (
                <Alert severity="error">
                  There was an error retrieving additional country fields.
                </Alert>
              )}
            </Fragment>
          );

        case "date":
          return (
            <SignupFormInputWrapper key={key}>
              <SignupFormLabel htmlFor={`signup_${key}`}>
                {label}
              </SignupFormLabel>
              <DatePicker
                sx={{ width: "100%", maxWidth: "500px" }}
                maxDate={new Date(subYears(Date.now(), 18))}
                openTo="year"
                error={!elementValidation.isValid}
                errorHelperText={elementValidation.errorHelper as JSX.Element}
                value={
                  elementData.value !== "" ? new Date(elementData.value) : null
                }
                onChange={(value: Date | null) => {
                  handleFormDataOnChange({
                    key,
                    value: formatDate(value),
                    type,
                    emptyValue,
                    additionalQuestion: !!additionalQuestion,
                  });
                }}
              />
            </SignupFormInputWrapper>
          );

        case "radio":
          return (
            <SignupFormInputWrapper key={key}>
              <SignupFormLabel htmlFor={`signup_${key}`}>
                {label}
              </SignupFormLabel>
              {labelHelper && <SubLabel>{labelHelper}</SubLabel>}
              <FormControl sx={{ display: "block" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={elementData.value}
                  onChange={(event, value) => {
                    handleFormDataOnChange({
                      key,
                      value,
                      type,
                      emptyValue,
                      additionalQuestion: !!additionalQuestion,
                    });
                  }}
                >
                  {options &&
                    options.map((option, index) => {
                      return (
                        <RadioButton
                          sx={
                            index ? { marginTop: "25px" } : { marginTop: "5px" }
                          }
                          key={option.value}
                          value={option.value}
                          label={option.label}
                          subLabel={option.subLabel}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </SignupFormInputWrapper>
          );

        case "checkbox":
          return (
            <SignupFormInputWrapper key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={elementData.value === "true"}
                    onChange={(event, value) => {
                      handleFormDataOnChange({
                        key,
                        value: `${value}`,
                        type,
                        emptyValue,
                        additionalQuestion: !!additionalQuestion,
                      });
                    }}
                  />
                }
                label={renderTextWithLink(label)}
              />
            </SignupFormInputWrapper>
          );
        default:
          return null;
      }
    });
  };

  const renderQuestionBlock = (
    data: SignupGeneralBlockQuestionsType,
    key: string
  ) => {
    const { groupTitle, properties } = data;

    let updatedProperties = [...properties];
    if (key === "contact") {
      if (additionalQuestions && additionalQuestions.properties.length > 0) {
        const corIndex = properties.findIndex(
          (property) => property.key === ADDRESS_COUNTRY_KEY
        );
        updatedProperties = [
          ...updatedProperties.slice(0, corIndex + 1),
          ...additionalQuestions.properties,
          ...updatedProperties.slice(corIndex + 1),
        ];
      }
    }
    return (
      <QuestionBlockWrapper key={groupTitle.replace(/\s/g, "")}>
        <Typography
          component="h3"
          variant="h3"
          sx={{ fontSize: "22px", marginBottom: "20px" }}
        >
          {groupTitle}
        </Typography>
        {renderQuestions(updatedProperties)}
      </QuestionBlockWrapper>
    );
  };

  const getSignupDataBody = (
    fData: SignupFormDataType,
    aData: SignupFormDataType
  ): SignupPostDataType | Record<string, string | CqqObjectType> => {
    const fDataProperties = Object.keys(fData).reduce(
      (acc, data) => [...acc, ...fData[data]],
      [] as SignupDataType[]
    );

    const aDataProperties = Object.keys(aData).reduce(
      (acc, data) => [...acc, ...aData[data]],
      [] as SignupDataType[]
    );

    const finalProperties = [
      ...fDataProperties,
      ...aDataProperties,
      { key: "access_token", value: tokenId, isCqq: false },
    ] as (
      | SignupDataType
      | { key: string; value: string | null; isCqq: boolean }
    )[];

    // Affiliate id (cxd)
    const cxdRef = getCookie("clickid");
    if (cxdRef) {
      finalProperties.push({
        key: "cxdRef",
        value: cxdRef,
        isCqq: false,
      });
    }

    // Affiliate id (x-tracking-id)
    const xTrackingId = getCookie("x-tracking-id");
    finalProperties.push({
      key: "x_tracking_id",
      value: xTrackingId || null,
      isCqq: false,
    });

    const body = finalProperties.reduce(
      (acc, data) => {
        if (data.isCqq) {
          acc = { ...acc, cqq: { ...acc.cqq, [data.key]: data.value } };
        } else {
          acc = { ...acc, [data.key]: data.value };
        }
        return acc;
      },
      { cqq: {} }
    );
    return body;
  };

  const handlePostAuth0 = async () => {
    try {
      const bodyKeys = [
        "email",
        "password",
        "date_of_birth",
        "addr_street",
        "addr_line_2",
        "addr_city",
        "addr_zip",
        "first_name",
        "last_name",
        "telephone",
        "addr_country",
        "title",
        "nationality",
      ];
      const signupData = formData[`${stepIndex}`].reduce((acc, data) => {
        if (bodyKeys.includes(data.key)) {
          acc = { ...acc, [data.key]: data.value };
        }
        return acc;
      }, {}) as SignupAuth0DataType;
      const brandId = process.env.REACT_APP_WHITELABEL as string;
      await auth0Signup(signupData);
      const result = await postOauthToken({
        body: {
          realm: "Username-Password-Authentication",
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
          scope: "openid",
          grant_type: "http://auth0.com/oauth/grant-type/password-realm",
          username: signupData.email,
          password: signupData.password,
        },
      }).unwrap();
      if (result.access_token) {
        setTokenId(result.access_token);
      }
      const userMetadata = signupData as Auth0UserMetadata;
      updateUserMetadata({
        brandId,
        userMetadata,
        token: result.access_token,
      }).unwrap();

      setStepIndex(stepIndex + 1);
      setLockEmailAndPassword(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.data?.status?.statusCode === 409) {
        setUserEmailExist(true);
      }
      setErrorDialogData({
        show: true,
        dialogTitleText: error.data?.status?.error || "Invalid Details",
        dialogContentText:
          error.data?.status?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const routeToTD365Home = () => {
    window.location.replace(
      process.env.REACT_APP_WHITELABEL === "TD365AU"
        ? "https://tradedirect365.com.au"
        : "https://td365.com"
    );
  };

  const [postLogin] = usePostLoginMutation();

  const handlePostSignupData = async () => {
    try {
      const body = getSignupDataBody(formData, additionalFormData);
      const res = await postSignupData({
        brandId: process.env.REACT_APP_WHITELABEL as string,
        body,
      }).unwrap();
      if (
        res?.alert &&
        ["TD365AU", "TD365UK"].includes(
          process.env.REACT_APP_WHITELABEL as string
        )
      ) {
        setShowCQQFailModal(true);
        setCQQAlertText(res.alert);
      } else {
        try {
          const incompleteSignup = localStorage.getItem("incomplete_signup");
          if (!incompleteSignup) {
            const result = await postOauthToken({
              body: {
                realm: "Username-Password-Authentication",
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
                scope: "openid",
                grant_type: "http://auth0.com/oauth/grant-type/password-realm",
                username: body.email as string,
                password: body.password as string,
              },
            }).unwrap();

            if (result.access_token) {
              localStorage.setItem("access_token", result.access_token);
              localStorage.setItem("id_token", result.id_token);

              postLogin({
                brandId: process.env.REACT_APP_WHITELABEL as string,
              });
            }
          } else {
            localStorage.removeItem("incomplete_signup");
          }
          navigate("/");
        } catch (error) {
          navigate("/login");
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorDialogData({
        show: true,
        dialogTitleText: error.data?.status?.error || "Signup Failed",
        dialogContentText:
          error.data?.status?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const handleNextButtonClick = () => {
    if (stepIndex === 0) {
      if (lockEmailAndPassword) {
        setStepIndex(stepIndex + 1);
      } else {
        handlePostAuth0();
      }
    } else if (stepIndex < numberOfSteps - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      handlePostSignupData();
    }
  };

  const handlePreviousButtonClick = () => {
    setStepIndex(stepIndex - 1);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogData({
      show: false,
      dialogTitleText: "",
      dialogContentText: "",
    });
  };

  const renderData = () => {
    if (pageData) {
      return (
        <>
          {Object.keys(pageData).map((key) => {
            return renderQuestionBlock(
              pageData[
                key as keyof SignupStepType
              ] as SignupGeneralBlockQuestionsType,
              key
            );
          })}
          <Button
            sx={{ width: "100%", maxWidth: "500px" }}
            type="button"
            disabled={!isFormValid()}
            onClick={handleNextButtonClick}
            text={stepIndex === numberOfSteps - 1 ? "FINISH" : "NEXT"}
          />
        </>
      );
    }
    return <Alert severity="error">No data</Alert>;
  };

  if (stepFetching) {
    return (
      <PageContentWrapper>
        <Loading />
      </PageContentWrapper>
    );
  }

  return (
    <PageWrapper>
      <Title>Signup</Title>
      <PageContent>
        <SignupFormWrapper autoComplete="off">
          {stepIndex > 0 && (
            <Button
              sx={{ width: "100%", maxWidth: "100px", marginBottom: "30px" }}
              type="button"
              onClick={handlePreviousButtonClick}
              text="PREVIOUS"
            />
          )}
          {renderData()}
          {errorDialogData.show && (
            <DialogModal
              open
              title={errorDialogData.dialogTitleText}
              text={errorDialogData.dialogContentText}
              handleClose={handleCloseErrorDialog}
            />
          )}
          {showFailCQQModal && (
            <DialogModal
              open
              title="Application Alert"
              text={cqqAlertText}
              handleClose={routeToTD365Home}
            />
          )}
        </SignupFormWrapper>
      </PageContent>
      {(postSignupLoading ||
        updateUserMetadataLoading ||
        additionalQFetching ||
        loginLoading) && <Loading />}
    </PageWrapper>
  );
}

export default Signup;
