import React, { useState } from "react";
import styled from "@emotion/styled";
import { Typography, IconButton, Drawer } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useMobile } from "hooks/useDisplay";
import { SideNav, Button } from "components";
import { breakpoints } from "utils";
import { isTDBrand, parseJwt } from "pages/utils";
import { useSelector } from "react-redux";
import { PortalBrandStateType } from "redux/features/portalbrand/portalBrandSlice";

const Header = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${(props: { theme: Theme }) =>
    isTDBrand() ? "#fff" : props.theme.palette.primary.main};
  box-shadow: 0 0 8px
    ${(props: { theme: Theme }) => props.theme.palette.primary.main};
  z-index: 1;
`;

const Container = styled.div`
  max-width: ${breakpoints[3]}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 10px;
  gap: 10px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Logo = styled.img`
  max-width: ${(props: { mobile: boolean }) =>
    props.mobile ? "200px" : "none"};
  max-height: ${(props: { mobile: boolean }) =>
    props.mobile ? "30px" : "50px"};
  vertical-align: middle;
`;

type Props = {
  className: string;
};

export default function TopHeader({ className }: Props) {
  const theme = useTheme();
  const mobile = useMobile();
  const [menuOpen, setMenuOpen] = useState(false);

  const { logo } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand.info
  );

  const accessToken = localStorage.getItem("access_token");
  const idToken = localStorage.getItem("id_token");
  let username = "";
  if (idToken) {
    const data = parseJwt(idToken);
    username = `${data["https://tradenation/name"]}`;
  }

  const navigate = useNavigate();

  const loginLogout = () => {
    if (accessToken) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("incomplete_signup");
    }

    navigate("/login");
  };

  const textColor = isTDBrand()
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText;

  // Logo
  const displayLogo = () => {
    const brandId = process.env.REACT_APP_WHITELABEL as string;
    if (brandId === "TD365") {
      return (
        <a href="https://td365.com/" target="_blank" rel="noopener noreferrer">
          <Logo src={logo} alt="Logo" mobile={mobile} />
        </a>
      );
    }

    return (
      <Link to={accessToken ? "/" : "/login"}>
        <Logo src={logo} alt="Logo" mobile={mobile} />
      </Link>
    );
  };

  return (
    <>
      <Header theme={theme} className={className}>
        <Container>
          <Group>
            {mobile && accessToken && (
              <IconButton
                sx={{ color: textColor }}
                onClick={() => setMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            {displayLogo()}
          </Group>
          {accessToken && (
            <Group>
              {!mobile && (
                <Typography variant="body1" component="p" color={textColor}>
                  {username}
                </Typography>
              )}
              {window.location.pathname !== "/login" && (
                <Button
                  text={accessToken ? "Logout" : "Log in"}
                  variant="text"
                  sx={{ color: textColor }}
                  onClick={loginLogout}
                />
              )}
            </Group>
          )}
        </Container>
      </Header>
      {accessToken && (
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.light,
            },
          }}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <SideNav handleMenuOpen={setMenuOpen} />
        </Drawer>
      )}
    </>
  );
}
