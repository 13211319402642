/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ClientTradingAccountsType,
  AfterActionFeedbackType,
  AddClientTradingAccountPlatformType,
  PaymentHistoryType,
  TradingAccountsCurrencies,
} from "redux/types";
import { getRtkQueryTagTypes } from "redux/utils";

export const tradingAccountsApi = createApi({
  reducerPath: "tradingaccountsapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTradingAccounts: builder.query<
      ClientTradingAccountsType,
      { brandId: string; params?: string }
    >({
      query: ({ brandId, params }) =>
        `${brandId}/user/accounts/${params ? `?${params}` : ""}`,
      providesTags: ["TradingAccounts"],
      keepUnusedDataFor: 0,
    }),
    getTradingAccountsCurrencies: builder.query<
      TradingAccountsCurrencies,
      { brandId: string }
    >({
      query: ({ brandId }) => `${brandId}/backend/`,
      providesTags: ["TradingAccountsCurrencies"],
    }),
    getAddTradingAccountPlatforms: builder.query<
      AddClientTradingAccountPlatformType,
      { isDemo?: boolean }
    >({
      query: ({ isDemo = false }) =>
        `user/add_trading_account_platforms/${isDemo ? "?demo=true" : ""}`,
    }),
    addTradingAccount: builder.mutation<
      AfterActionFeedbackType,
      {
        brandId: string;
        body: {
          backend_id: number;
          account_type: string;
          currency: string;
          mt4_password?: string;
          isDemo?: boolean;
        };
      }
    >({
      query: ({ brandId, body }) => ({
        url: `${brandId}/add_trading_account/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TradingAccounts", "TradingAccountsCurrencies"],
    }),
    changePassword: builder.mutation<
      AfterActionFeedbackType,
      { brandId: string; body: { account_id: string; new_password: string } }
    >({
      query: ({ brandId, body }) => {
        return {
          url: `${brandId}/update_trading_account/`,
          method: "PATCH",
          body,
        };
      },
    }),
    getDepositData: builder.query<
      { url: string },
      { brandId: string; account_id: string }
    >({
      query: ({ brandId, account_id }) => `${brandId}/deposit/${account_id}/`,
    }),
    withdraw: builder.mutation<
      AfterActionFeedbackType,
      { brandId: string; accountId: number; body: { amount: string } }
    >({
      query: ({ brandId, accountId, body }) => {
        return {
          url: `${brandId}/withdraw/${accountId}/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["TradingAccounts", "PaymentHistory"],
    }),
    internalTransfer: builder.mutation<
      AfterActionFeedbackType,
      {
        body: { fromAccountId: number; toAccountId: number; amount: string };
      }
    >({
      query: ({ body }) => {
        return {
          url: `user/internal_transfer/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["PaymentHistory"],
    }),
    getPaymentHistory: builder.query<
      PaymentHistoryType,
      { brandId: string; params?: string }
    >({
      query: ({ brandId, params }) =>
        `${brandId}/user/transactions/${params ? `?${params}` : ""}`,
      providesTags: ["PaymentHistory"],
    }),
  }),
});

export const {
  useGetTradingAccountsQuery,
  useGetTradingAccountsCurrenciesQuery,
  useGetAddTradingAccountPlatformsQuery,
  useAddTradingAccountMutation,
  useChangePasswordMutation,
  useGetDepositDataQuery,
  useWithdrawMutation,
  useInternalTransferMutation,
  useGetPaymentHistoryQuery,
} = tradingAccountsApi;
