import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Title } from "react-head";
import styled from "@emotion/styled";
import { useMobile } from "hooks/useDisplay";
import { SideNav } from "components";
import {
  AccountSummary,
  AddTradingAccount,
  Personal,
  ChangePassword,
  DepositFunds,
  WithdrawFunds,
  // InternalTransfer,
  PaymentHistory,
} from "pages";
import { breakpoints } from "utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
`;

const Content = styled.div`
  max-width: ${breakpoints[3]}px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const HomePageWrapper = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
`;

export default function Home() {
  const mobile = useMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login", { replace: true });
    } else if (location.pathname === "/") {
      navigate("/accounts", { replace: true });
    }
  }, [location]);

  if (!accessToken) {
    return null;
  }

  return (
    <Container>
      <Title>Home</Title>
      <Content>
        {!mobile && <SideNav />}
        <HomePageWrapper>
          <Routes>
            <Route path="/accounts" element={<AccountSummary />} />
            <Route path="/account-add" element={<AddTradingAccount />} />
            <Route
              path="/account-add-demo"
              element={<AddTradingAccount isDemo />}
            />
            <Route path="/personal" element={<Personal />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/deposit/" element={<DepositFunds />} />
            <Route path="/deposit/:id" element={<DepositFunds />} />
            <Route path="/withdraw" element={<WithdrawFunds />} />
            {/* <Route path="/internal" element={<InternalTransfer />} /> */}
            <Route path="/history" element={<PaymentHistory />} />
          </Routes>
        </HomePageWrapper>
      </Content>
    </Container>
  );
}
