/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from "react";
import { Divider, Alert } from "@mui/material";
import { Title } from "react-head";
import { useTheme } from "@mui/material/styles";
import {
  TextInput,
  Button,
  DatePicker,
  Loading,
  PageTitle,
  DialogModal,
  ComboBox,
} from "components";
import { checkTokenExpired, formatDate } from "pages/utils";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "redux/features/userprofileapi/userProfileApi";
import {
  UserPropertyType,
  UserPropertyKeyType,
} from "redux/features/userprofileapi/types";
import styled from "@emotion/styled";
import { mediaQueries } from "utils";
import countries from "pages/utils/countries.json";
import { DialogModalType, AfterActionFeedbackType } from "redux/types";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries[1]} {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px;
  }
`;

const DataItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mediaQueries[1]} {
    flex-direction: row;
    align-items: center;
  }
`;

const DataItemName = styled.h4`
  margin: 10px 20px 10px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
`;

const TextInputWrapper = styled.div`
  width: 100%;

  ${mediaQueries[1]} {
    width: 250px;
  }
`;

function Personal() {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    data: client,
    isFetching,
    error,
  } = useGetUserQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
  });

  useEffect(() => {
    checkTokenExpired(error, navigate);
  }, [error]);

  const [editedProperties, setEditedProperties] = useState<UserPropertyType[]>(
    []
  );

  const [updateUser, { isLoading: updatingUser }] = useUpdateUserMutation();

  const [editMode, setEditMode] = useState(false);

  const handleEditButtonClick = () => {
    const editableProperties =
      client?.filter((property) => property.editable) || [];
    setEditedProperties([...editableProperties]);
    setEditMode(true);
  };

  const getEditedProperty = (key: string): UserPropertyType => {
    const editedProperty = editedProperties.find(
      (property) => property.key === key
    ) as UserPropertyType;

    return editedProperty;
  };

  const handleEditedPropertyChange = (key: string, value: string) => {
    const updatedProperty = getEditedProperty(key);
    const nonEditedProperties = editedProperties.filter(
      (property) => property.key !== key
    );
    if (updatedProperty) {
      setEditedProperties([
        ...nonEditedProperties,
        { ...updatedProperty, value },
      ]);
    }
  };

  const onCancelEditClick = () => {
    setEditMode(false);
  };

  const getUpdatedProperties = () => {
    return editedProperties.reduce((acc, property) => {
      const originalProperty = client?.find(
        (item) => item.key === property.key
      );
      if (originalProperty && property.value !== originalProperty.value) {
        acc = { ...acc, [property.key]: property.value };
      }
      return acc;
    }, {} as Record<keyof UserPropertyKeyType, string>);
  };

  const [updateFeedback, setUpdateFeedback] = useState<DialogModalType>();
  const onSaveEditClick = async () => {
    const res = (await updateUser({
      brandId: process.env.REACT_APP_WHITELABEL as string,
      body: getUpdatedProperties(),
    })) as { data?: AfterActionFeedbackType; error?: Error };

    if (res?.error) {
      checkTokenExpired(res.error, navigate);
      setUpdateFeedback({
        title: "Client Details Update Error",
        text: "Something went wrong, client details update was unsuccessful. Please try again later.",
        handleClose: () => setUpdateFeedback(undefined),
        color: theme.palette.error.main,
      });
    } else if (res?.data?.error) {
      setUpdateFeedback({
        title: "FAIL",
        text: res.data.error,
        handleClose: () => setUpdateFeedback(undefined),
        color: theme.palette.error.main,
      });
    } else {
      setUpdateFeedback({
        title: "SUCCESS",
        text: "Your personal information was changed with success.",
        handleClose: () => setUpdateFeedback(undefined),
        color: theme.palette.success.main,
      });
      setEditMode(false);
    }
  };

  const validateProperty = (property: UserPropertyType) => {
    return !!property.value;
  };

  const isFormValid = () => {
    const invalidProperties = editedProperties.filter((property) => {
      if (property.editable && property.validation) {
        return !validateProperty(property);
      }
      return false;
    });

    if (invalidProperties.length > 0) {
      return false;
    }
    return true;
  };

  const disableSaveButton = () => {
    const updatedPropertiesLength = Object.keys(getUpdatedProperties()).length;
    if (!isFormValid() || !updatedPropertiesLength) {
      return true;
    }
    return false;
  };

  const getEditableInputType = (property: UserPropertyType) => {
    const editedProperty = getEditedProperty(property.key);
    const isValid = editedProperty?.validation
      ? validateProperty(editedProperty)
      : true;
    switch (property.type) {
      case "text":
        if (property.key === "addr_country") {
          const listOfCountries =
            editedProperty && editedProperty.value
              ? countries.map((country) => ({
                  label: country.display_name,
                  value: country.value,
                }))
              : [];
          const selectedCountry =
            listOfCountries.find((c) => c.label === editedProperty?.value)
              ?.value ||
            editedProperty.value ||
            "";
          return (
            <TextInputWrapper>
              <ComboBox
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  "&&& > div > div": { padding: "2px 7px" },
                  [mediaQueries[1]]: { width: "250px" },
                }}
                options={listOfCountries}
                value={selectedCountry}
                onChangeHandler={(option: { label: string; value: string }) => {
                  handleEditedPropertyChange(property.key, option.value);
                }}
              />
            </TextInputWrapper>
          );
        }

        return (
          <TextInputWrapper>
            <TextInput
              id={`user_${property.key}`}
              size="small"
              sx={{ width: "100%", [mediaQueries[1]]: { width: "250px" } }}
              error={!isValid}
              value={editedProperty ? editedProperty.value : ""}
              helperText={!isValid ? "Error: missing value" : ""}
              onChange={(value: string) => {
                handleEditedPropertyChange(property.key, value);
              }}
            />
          </TextInputWrapper>
        );
      case "date":
        return (
          <DatePicker
            sx={{ width: "100%", [mediaQueries[1]]: { width: "250px" } }}
            error={!isValid}
            errorHelperText="Error: missing value"
            value={new Date(editedProperty.value)}
            onChange={(value: Date | null) => {
              handleEditedPropertyChange(property.key, formatDate(value));
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const renderClientDetails = () => {
    return client?.map((property) => (
      <React.Fragment key={property.key}>
        <DataItemWrapper>
          {!editMode || !property.editable ? (
            <>
              <DataItemName>{property.label}</DataItemName>

              <span>{property.value}</span>
            </>
          ) : (
            <>
              <DataItemName>
                <label htmlFor={`user_${property.key}`}>{property.label}</label>
              </DataItemName>
              {getEditableInputType(property)}
            </>
          )}
        </DataItemWrapper>
        <Divider sx={{ margin: "10px 0" }} />
      </React.Fragment>
    ));
  };

  const displayContent = () => {
    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return (
        <Alert severity="error">
          There was a problem retrieving your personal information. Please try
          again later.
        </Alert>
      );
    }

    return (
      <>
        <PageWrapper>
          {renderClientDetails()}
          <ButtonWrapper>
            {editMode ? (
              <>
                <Button
                  sx={{ width: "100px" }}
                  size="large"
                  type="button"
                  onClick={onCancelEditClick}
                  text="Cancel"
                />
                <Button
                  sx={{ width: "100px" }}
                  size="large"
                  onClick={onSaveEditClick}
                  disabled={disableSaveButton()}
                  text="Save"
                />
              </>
            ) : (
              <Button
                sx={{ width: "100px" }}
                size="large"
                onClick={handleEditButtonClick}
                text="Edit"
              />
            )}
          </ButtonWrapper>
        </PageWrapper>
        {updatingUser && <Loading />}
        {updateFeedback && (
          <DialogModal
            open
            title={updateFeedback.title}
            text={updateFeedback.text}
            handleClose={updateFeedback.handleClose}
            color={updateFeedback.color}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Title>Personal</Title>
      <PageTitle title="PERSONAL INFORMATION" />
      {displayContent()}
    </>
  );
}

export default Personal;
