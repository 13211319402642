/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { OptionType } from "components/types";

type ComboBoxType = {
  options: OptionType[];
  label?: string;
  sx?: object;
  value: string;
  onChangeHandler: (option: OptionType) => void;
  isUK?: boolean;
  id?: string;
};
export default function ComboBox({
  options,
  label,
  value,
  onChangeHandler,
  sx,
  id,
}: ComboBoxType) {
  // const [value, setValue] = React.useState<OptionType | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const valueOption = options.find((option) => option.value === value);

  const reset = () => {
    // setValue(null);
    setInputValue("");
  };
  return (
    <Autocomplete
      value={valueOption || null}
      onChange={(event, newValue) => {
        if (newValue?.value) {
          onChangeHandler(newValue);
        }
        reset();
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id={`combo-box-${id || label}`}
      options={options}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            form: {
              autoComplete: "off",
            },
          }}
        />
      )}
    />
  );
}
