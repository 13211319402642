/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CqqObjectType,
  SignupAdditionalPropertyType,
  SignupAuth0DataType,
  Auth0UserMetadata,
  Auth0SignupBody,
  Auth0SignupResponse,
  SignupPostDataType,
  SignupStepType,
  Auth0UserUpdateResponse,
} from "redux/types";

export async function resetPasswordWithAuth0(email: string) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  const urlencoded = new URLSearchParams();
  urlencoded.append("email", email);
  urlencoded.append("connection", "Username-Password-Authentication");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      // @ts-ignore
      requestOptions
    );
    return res;
  } catch (error) {
    const str = JSON.stringify(error);
    throw Error(str);
  }
}

export const signupApi = createApi({
  reducerPath: "signupapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getSignupQuestions: builder.query<SignupStepType, { step: string }>({
      query: ({ step }) => `${step}/`,
    }),

    getAdditionalQuestions: builder.query<
      SignupAdditionalPropertyType,
      { brandId: string; country: string }
    >({
      query: ({ brandId, country }) => `/signup/${brandId}/step1${country}/`,
    }),

    resetPassword: builder.query({
      query: () => `/signup/reset-password?email=test_27062022@mailinator.com`,
    }),
    postSignupData: builder.mutation<
      { status: string; alert?: string },
      {
        brandId: string;
        body: SignupPostDataType | Record<string, string | CqqObjectType>;
      }
    >({
      query: ({ brandId, body }) => ({
        url: `/signup/${brandId}/submit/`,
        method: "POST",
        body,
      }),
    }),
    auth0UpdateUserMetadata: builder.mutation<
      Auth0UserUpdateResponse,
      { brandId: string; userMetadata: Auth0UserMetadata; token: string }
    >({
      query: ({ brandId, userMetadata, token }) => ({
        url: `/signup/${brandId}/auth0/user_metadata/`,
        method: "PATCH",
        body: userMetadata,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export async function auth0Signup(
  signupData: SignupAuth0DataType
): Promise<Auth0SignupResponse> {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
  const body: Auth0SignupBody = {
    client_id: clientId,
    email: signupData.email,
    password: signupData.password,
    connection: "Username-Password-Authentication",
    given_name: signupData.first_name,
    family_name: signupData.last_name,
  };
  const signupResponse = await fetch(`${domain}/dbconnections/signup`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    redirect: "follow",
  });
  return signupResponse.json();
}

export const {
  useGetSignupQuestionsQuery,
  useGetAdditionalQuestionsQuery,
  usePostSignupDataMutation,
  useAuth0UpdateUserMetadataMutation,
} = signupApi;
