import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import { Typography, Alert } from "@mui/material";
import {
  PageTitle,
  Dropdown,
  TextInput,
  Button,
  Loading,
  DialogModal,
} from "components";
import {
  useGetTradingAccountsQuery,
  useInternalTransferMutation,
} from "redux/features/tradingaccountsapi/tradingAccountsApi";
import {
  AfterActionFeedbackType,
  DialogModalType,
  TradingAccountType,
} from "redux/types";
import {
  getAccountsWithFunds,
  getTradingAccount,
  getTradingAccountsOptions,
} from "pages/utils";
import { FIELD_REQUIRED_MESSAGE, mediaQueries } from "utils";

export default function InternalTransfer() {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    data: { results: tradingAccounts } = {
      results: [],
    },
    isFetching,
    error: errorTradingAccounts,
  } = useGetTradingAccountsQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
  });

  const [error, setError] = useState("");

  const [selectedFromAccount, setSelectedFromAccount] =
    useState<TradingAccountType | null>();

  const onFromAccountChange = (accountId: string) => {
    const account = getTradingAccount(tradingAccounts, accountId);
    if (account) {
      setSelectedFromAccount(account);
    }
  };

  const [toAccounts, setToAccounts] = useState<TradingAccountType[]>([]);
  const [selectedToAccount, setSelectedToAccount] =
    useState<TradingAccountType | null>(null);

  const onToAccountChange = (accountId: string) => {
    const account = getTradingAccount(tradingAccounts, accountId);
    if (account) {
      setSelectedToAccount(account);
    }
  };

  const [amount, setAmount] = useState("");
  const [canValidate, setCanValidate] = useState(false);

  useEffect(() => {
    const accountsWithFunds = getAccountsWithFunds(tradingAccounts);
    if (tradingAccounts.length === 0) {
      setSelectedFromAccount(null);
      setError("You have no accounts!");
    } else if (accountsWithFunds.length === 0 && tradingAccounts.length > 0) {
      setSelectedFromAccount(null);
      setError("You have no accounts with funds!");
    } else if (tradingAccounts.length === 1) {
      setSelectedFromAccount(accountsWithFunds[0]);
      setSelectedToAccount(null);
      setError("You only have 1 account!");
    } else if (accountsWithFunds.length > 0) {
      setSelectedFromAccount(accountsWithFunds[0]);
      setError("");
    }
  }, [tradingAccounts]);

  useEffect(() => {
    if (selectedFromAccount) {
      if (selectedFromAccount.id === selectedToAccount?.id) {
        setSelectedToAccount(null);
      }
      setToAccounts(
        tradingAccounts.filter((acc) => acc.id !== selectedFromAccount.id)
      );
    }

    setAmount("");
    setCanValidate(false);
  }, [selectedFromAccount]);

  useEffect(() => {
    if (!selectedToAccount) {
      setSelectedToAccount(toAccounts[0]);
    }
  }, [toAccounts]);

  const [transfer, { isLoading }] = useInternalTransferMutation();
  const [transferFeedback, setTransferFeedback] = useState<DialogModalType>();

  const onTransferHandle = async () => {
    setCanValidate(true);

    if (selectedFromAccount && selectedToAccount && Number(amount) > 0) {
      const res = (await transfer({
        body: {
          fromAccountId: selectedFromAccount.id,
          toAccountId: selectedToAccount.id,
          amount,
        },
      })) as { data?: AfterActionFeedbackType; error?: Error };

      if (res?.error) {
        setTransferFeedback({
          title: "FAIL",
          text: "There was a problem transferring funds. Please try again later.",
          handleClose: () => setTransferFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else if (res?.data?.error) {
        setTransferFeedback({
          title: "FAIL",
          text: res.data.error,
          handleClose: () => setTransferFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else {
        setTransferFeedback({
          title: "SUCCESS",
          text: "The internal transfer was processed with success.",
          handleClose: () => navigate(`/history`),
          color: theme.palette.success.main,
        });
      }
    }
  };

  const displayContent = () => {
    if (isFetching) {
      return <Loading />;
    }

    if (errorTradingAccounts) {
      return (
        <Alert severity="error">
          There was a problem retrieving the trading accounts. Please try again
          later.
        </Alert>
      );
    }

    if (error) {
      return <Alert severity="warning">{error}</Alert>;
    }

    return (
      <>
        {getAccountsWithFunds(tradingAccounts).length > 0 && (
          <>
            <Typography variant="body1" component="p">
              You can transfer funds between the trading accounts you have with
              us.
            </Typography>
            <Typography variant="body1" component="p">
              Please enter the desired transfer amount and select the source and
              destination accounts.
            </Typography>
            <Typography variant="body1" component="p">
              Internal transfers are processed immediately.
            </Typography>
            <Dropdown
              label="From account"
              labelIsBold
              options={getTradingAccountsOptions(
                getAccountsWithFunds(tradingAccounts)
              )}
              value={
                selectedFromAccount ? selectedFromAccount.id.toString() : ""
              }
              onChange={onFromAccountChange}
              extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
            />
          </>
        )}
        {selectedFromAccount && selectedToAccount && (
          <>
            <Dropdown
              label="To account"
              labelIsBold
              options={getTradingAccountsOptions(toAccounts, false)}
              value={selectedToAccount.id.toString()}
              onChange={onToAccountChange}
              extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
            />
            <TextInput
              title="Amount"
              titleIsBold
              value={amount}
              size="small"
              required
              type="number"
              error={canValidate && !Number(amount)}
              helperText={FIELD_REQUIRED_MESSAGE}
              onChange={setAmount}
              prefix={selectedFromAccount.currency}
              max={Number(selectedFromAccount.balance)}
              sx={{
                maxWidth: "100%",
                [mediaQueries[1]]: { maxWidth: "480px" },
              }}
            />
            <Button
              sx={{
                width: "100%",
                [mediaQueries[1]]: { width: "initial" },
              }}
              text="Request internal transfer"
              onClick={onTransferHandle}
            />
          </>
        )}
        {isLoading && <Loading />}
        {transferFeedback && (
          <DialogModal
            open
            title={transferFeedback.title}
            text={transferFeedback.text}
            handleClose={transferFeedback.handleClose}
            color={transferFeedback.color}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Title>Internal Transfer</Title>
      <PageTitle title="INTERNAL TRANSFER" />
      {displayContent()}
    </>
  );
}
