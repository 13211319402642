/* eslint-disable import/prefer-default-export */
type RtkQueryTypes =
  | "PersonalDetails"
  | "TradingAccounts"
  | "PaymentHistory"
  | "TradingAccountsCurrencies";

const tagTypes: RtkQueryTypes[] = [
  "PersonalDetails",
  "TradingAccounts",
  "PaymentHistory",
  "TradingAccountsCurrencies",
];

export const getRtkQueryTagTypes = () => {
  return tagTypes;
};
